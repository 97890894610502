<template>
  <div
    class="modal-confirm modal modal-alert"
    :class="'modal-alert-' + classType"
    tabindex="-1"
    role="dialog"
    aria-labelledby="alertModaTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered collapsed" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalCenterTitle">
            <i class="zmdi zmdi-alert-circle-o"></i>
            {{ title }}
          </h5>
        </div>
        <div class="modal-body" v-html="content"></div>
        <div class="modal-footer">
          <button
            v-on:click.prevent="close(true)"
            v-if="type == 'info'"
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
          >
            {{ acceptText }}
          </button>

          <template v-if="type == 'confirm'">
            <button
              v-on:click.prevent="close(false)"
              type="button"
              class="btn btn-link"
              data-dismiss="modal"
            >
              {{ cancelText }}
            </button>
            <button
              v-on:click.prevent="close(true)"
              type="button"
              class="btn btn-primary aceptbutton"
              data-dismiss="modal"
            >
              {{ acceptText }}
            </button>
          </template>
        </div>
      </div>
    </div>

    <button
      v-on:click.prevent="close(false)"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true"><i class="zmdi zmdi-close"></i></span>
    </button>
  </div>
</template>

<script>
var defaultData = function () {
  return {
    title: "", //Título del modal
    type: "info", //Info o confirm,
    classType: "info",
    acceptText: "Aceptar", //Texto del botón Aceptar: confirm(true)
    cancelText: "Cancelar", //Texto del botón Cancelar: confirm(false)
    content: "", //Contenido del cuerpo del modal
    confirm: function (ok) {}, //Función que se llama al Aceptar o Cancelar
  };
};

export default {
  data() {
    return defaultData();
  },
  mounted() {
    this.$bus.$on("close-confirm-dialog", () => this.closeDialog());
  },
  methods: {
    show(opt) {
      let self = this;
      Object.assign(this.$data, Object.assign(defaultData(), opt));

      showModal(".modal-confirm", null, function (event) {
        let ok = event && event.keyCode && event.keyCode == 13;
        self.confirm(ok);
      });
    },

    close(ok) {
      tplay.sendGrafanaEvent(
        tplay.grafana.event.BUTTON,
        {
          name: ok ? "Aceptar" : "Cancelar",
          sub_type: "toast",
          screen: this.$route.name,
        },
        tplay.grafana.actions.ENTER
      );

      this.confirm(ok);
      hideModal(".modal-confirm");
    },
    closeDialog() {
      hideModal(".modal-confirm");
    },
  },
  beforeDestroy() {
    this.$bus.$off("close-confirm-dialog", () => this.closeDialog());
  },
};
</script>
